<form [formGroup]="form">
  <div class="d-flex flex-column gap-3">
    <div class="d-flex flex-lg-row flex-column gap-1">
      <div class="d-flex flex-column gap-1 w-100">
        <span class="px-2">Típo de servicio</span>
        <p-dropdown formControlName="typeService" [options]="this.catalog.typeService"
          (onChange)="onChangeTypeService()" styleClass="w-100 h-50px border-0">
          <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="d-flex flex-row justify-content-start align-items-center gap-3">
              <span class="d-flex align-items-center gap-1">
                @if (selectedOption.label.departure === 'Aeropuerto') {<i class="bi bi-airplane-fill"></i>}
                @if (selectedOption.label.departure === 'Hotel') {<i class="bi bi-building-fill"></i>}
                {{selectedOption.label.departure}}
              </span>
              @if (selectedOption.label.roundtrip === true) {
              <i class="bi bi-arrow-left-right"></i>
              }
              @else {
              <i class="bi bi-arrow-right"></i>
              }
              <span class="d-flex align-items-center gap-1">
                @if (selectedOption.label.destination === 'Aeropuerto') {<i class="bi bi-airplane-fill"></i>}
                @if (selectedOption.label.destination === 'Hotel') {<i class="bi bi-building-fill"></i>}
                {{selectedOption.label.destination}}

                @if (selectedOption.label.roundtrip === true) {(Ida y vuelta)}
                @if (selectedOption.label.roundtrip === false) {(Sólo ida)}
              </span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="d-flex gap-3">
              <span class="d-flex gap-1">
                @if (item.label.departure === 'Aeropuerto') {<i class="bi bi-airplane-fill"></i>}
                @if (item.label.departure === 'Hotel') {<i class="bi bi-building-fill"></i>}
                {{item.label.departure}}
              </span>
              @if (item.label.roundtrip === true) {
              <i class="bi bi-arrow-left-right"></i>
              }
              @else {
              <i class="bi bi-arrow-right"></i>
              }
              <span class="d-flex gap-1">
                @if (item.label.destination === 'Aeropuerto') {<i class="bi bi-airplane-fill"></i>}
                @if (item.label.destination === 'Hotel') {<i class="bi bi-building-fill"></i>}
                {{item.label.destination}}

                @if (item.label.roundtrip === true) {(Ida y vuelta)}
                @if (item.label.roundtrip === false) {(Sólo ida)}
              </span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="d-flex flex-column gap-1 w-100">
        <span class="px-2">Desde</span>
        <p-dropdown formControlName="departure" [options]="this.catalog.departure" [group]="true" [showClear]="true"
          styleClass="w-100 h-50px border-0" [filter]="true" filterBy="label" placeholder="Selecciona una opción.">
          <ng-template let-selected pTemplate="selectedItem">
            <div class="d-flex">
              {{selected.label}}
            </div>
          </ng-template>
          <ng-template let-group pTemplate="group">
            {{group.label}}
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="d-flex align-items-center gap-2">
              @switch(item.type) {
              @case('HOTEL') { <i class="bi bi-building-fill"></i> }
              @case('AIRPORT') { <i class="bi bi-airplane-fill"></i> }
              @case('OTHER') { <i class="bi bi-geo-alt-fill"></i> }
              }
              <span>{{item.label}}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="d-flex flex-column gap-1 w-100">
        <span class="px-2">Destino</span>
        <p-dropdown formControlName="destination" [options]="this.catalog.destination" [group]="true" [showClear]="true"
          styleClass="w-100 h-50px border-0" [filter]="true" filterBy="label" placeholder="Selecciona una opción.">
          <ng-template let-selected pTemplate="selectedItem">
            <div class="d-flex">
              {{selected.label}}
            </div>
          </ng-template>
          <ng-template let-group pTemplate="group">
            {{group.label}}
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="d-flex align-items-center gap-2">
              @switch(item.type) {
              @case('HOTEL') { <i class="bi bi-building-fill"></i> }
              @case('AIRPORT') { <i class="bi bi-airplane-fill"></i> }
              @case('OTHER') { <i class="bi bi-geo-alt-fill"></i> }
              }
              <span>{{item.label}}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="d-flex flex-lg-row flex-column gap-1">
      <div class="d-flex flex-column gap-1 w-100">
        <span class="px-2">Fecha y hora de llegada</span>
        <p-calendar formControlName="arrivalDate" styleClass="w-100 h-50px" inputStyleClass="w-100 h-50px border-0"
          dateFormat="dd/mm/yy" [showTime]="true" [minDate]="minDate" [numberOfMonths]="2" />
      </div>
      <div class="d-flex flex-column gap-1 w-100">
        <span class="px-2">Fecha y hora de salida</span>
        <p-calendar formControlName="departureDate" styleClass="w-100 h-50px" inputStyleClass="w-100 h-50px border-0"
          dateFormat="dd/mm/yy" [showTime]="true" [minDate]="this.arrivalDate.value" [numberOfMonths]="2" />
      </div>

      <div class="d-flex gap-1 w-100">
        <div class="d-flex flex-column gap-1 w-50">
          <span class="px-3">Adultos</span>
          <p-dropdown formControlName="adults"
            [options]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]"
            styleClass="w-100 h-50px border-0">
            <ng-template pTemplate="selectedItem" let-selectedOption>
              <div class="text-center">{{ selectedOption }}</div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div class="ps-4">{{ item }}</div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="d-flex flex-column gap-1 w-50">
          <span class="px-3">Niños</span>
          <p-dropdown formControlName="childrens" [options]="[0,1,2,3,4,5,6,7,8,9,10]"
            styleClass="w-100 h-50px border-0">
            <ng-template pTemplate="selectedItem" let-selectedOption>
              <div class="text-center">{{ selectedOption }}</div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div class="ps-4">{{ item }}</div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>

      <div class="d-flex gap-1 w-100">
        <div class="d-flex flex-column gap-1 w-50">
          <span class="px-2">Moneda</span>
          <p-dropdown formControlName="currency" [options]="this.catalog.currency" styleClass="w-100 h-50px border-0">
            <ng-template pTemplate="selectedItem" let-selectedOption>
              <div class="ps-1">{{ selectedOption.label }}</div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div>{{ item.value }}</div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="d-flex flex-column-reverse gap-1 w-50">
          <button class="btn btn-primary h-50px" (click)="search()" [disabled]="this.form.invalid">Reservar</button>
        </div>
      </div>
    </div>
  </div>
</form>