import { Injectable, inject } from '@angular/core';
import { HttpService } from '@utils/http.service';

export interface IParamsFindRate {
  typeService: null | string,
  departure: null | string,
  destination: null | string,
  dates: {
    arrival: null | Date,
    departure: null | Date,
  },
  pax: {
    adults: null | number,
    childrens: null | number,
  },
  currency: null | string,
}

@Injectable({
  providedIn: 'root'
})
export class TransportationService {
  private http = inject(HttpService)

  public paramsFindRateParse = (params: any): IParamsFindRate => {
    let obj: IParamsFindRate = {
      typeService: null,
      departure: null,
      destination: null,
      dates: {
        arrival: null,
        departure: null,
      },
      pax: {
        adults: null,
        childrens: null,
      },
      currency: null,
    }

    if (params["typeService"]) {
      obj.typeService = params["typeService"];
    }

    if (params["destinations"]) {
      let destinations = params["destinations"].split(':')

      if (destinations[0] !== '') {
        obj.departure = destinations[0]
      }

      if (destinations[1] !== '') {
        obj.destination = destinations[1]
      }
    }

    if (params["dates"]) {
      let dates = params["dates"].split(':')

      if (dates[0] !== '') {
        obj.dates.arrival = new Date(parseInt(dates[0]))
      }

      if (dates[1] !== '') {
        obj.dates.departure = new Date(parseInt(dates[1]))
      }
    }

    if (params["pax"]) {
      let pax = params["pax"].split(':')

      if (pax[0] !== '') {
        obj.pax.adults = parseInt(pax[0])
      }

      if (pax[1] !== '') {
        obj.pax.childrens = parseInt(pax[1])
      }
    }

    if (params["currency"]) {
      obj.currency = params["currency"]
    }

    return obj
  }

  public getLocations = async () => await this.http.get(`/v1/transportation/locations`, false, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public findRate = async (typeService: any, departure: any, destination: any, arrivalDate: any, departureDate: any, adults: any, childrens: any, currency: any) => await this.http.get(`/v1/transportation/find?typeService=${typeService}&departure=${departure}&destination=${destination}&arrivalDate=${arrivalDate}&departureDate=${departureDate}&adults=${adults}&childrens=${childrens}&currency=${currency}`, false, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public createPayment = async (body: any) => await this.http.post(`/v1/payment/clip/transportation`, body, false, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)
}
