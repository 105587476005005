import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MainHeaderComponent } from '@components/main-header/main-header.component';
import { BookingBoxComponent } from '@components/booking-box/booking-box.component';
import { TabViewModule } from 'primeng/tabview';
import { CurrencyPipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MainHeaderComponent,
    BookingBoxComponent,
    TabViewModule,
    CurrencyPipe,
    TableModule,
    RouterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomePage {
  public tableRates: any[] = []

  ngOnInit() {
    this.tableRates = [
      {
        'title': 'Cancún Centro y Zona Hotelera',
        'price_1': 650,
        'price_2': 750,
        'price_3': 1100,
      },
      {
        'title': 'PUNTA SAM (Puerto Juarez hasta Villa del Palmar)',
        'price_1': 750,
        'price_2': 850,
        'price_3': 1200,
      },
      {
        'title': 'RIVIERA CANCUN (Moon Palace hasta Bahía Petempich)',
        'price_1': 750,
        'price_2': 850,
        'price_3': 1200,
      },
      {
        'title': 'PLAYA MUJERES (Marina V&V hasta Dreams Playa Mujeres)',
        'price_1': 800,
        'price_2': 900,
        'price_3': 1300,
      },
      {
        'title': 'COSTA MUJERES (Palladium Costa Mujeres hasta Riu Dunamar)',
        'price_1': 900,
        'price_2': 1000,
        'price_3': 1400,
      },
      {
        'title': 'PUERTO MORELOS (Crococum hasta Grand Residences)',
        'price_1': 800,
        'price_2': 900,
        'price_3': 1300,
      },
      {
        'title': 'PLAYA DEL CARMEN NORTE (Nickelodeon hasta Punta Maroma)',
        'price_1': 900,
        'price_2': 1000,
        'price_3': 1500,
      },
      {
        'title': 'PLAYA DEL CARMEN (Hacienda Tres Rios hasta Xcaret)',
        'price_1': 1100,
        'price_2': 1200,
        'price_3': 1700,
      },
      {
        'title': 'PUERTO AVENTURAS (Punta Venado hasta Catalonia Royal Tulum)',
        'price_1': 1200,
        'price_2': 1300,
        'price_3': 1900,
      },
      {
        'title': 'AKUMAL (Dorado Seaside hasta Bahia Principe)',
        'price_1': 1400,
        'price_2': 1500,
        'price_3': 2100,
      },
      {
        'title': 'RIVIERA MAYA TULUM (Chemuyil hasta Dreams Tulum)',
        'price_1': 1700,
        'price_2': 1800,
        'price_3': 2400,
      },
      {
        'title': 'TULUM CENTRO (Tulum Centro, Aldea Zama & La Veleta)',
        'price_1': 1800,
        'price_2': 1900,
        'price_3': 2600,
      },
      {
        'title': 'Zona Hotelera Tulum (Zona Costera de Boca Paila hasta Arco Maya)',
        'price_1': 1900,
        'price_2': 2000,
        'price_3': 2800,
      },
      {
        'title': 'CHIQUILLA HOLBOX (Desde / a Cancun o Riviera Maya)',
        'price_1': 2600,
        'price_2': 3100,
        'price_3': 4600,
      },
      {
        'title': 'TOUR A CHICHEN ITZA (Servicio por 12 horas. Solo transporte)',
        'price_1': 4200,
        'price_2': 4700,
        'price_3': 7200,
      },
      {
        'title': 'TOUR A TULUM (Servicio por 12 horas. Solo transporte)',
        'price_1': 4200,
        'price_2': 4700,
        'price_3': 7200,
      },
      {
        'title': 'TRASLADOS DENTRO DE CANCUN (Centro y Zona Hotelera)',
        'price_1': 500,
        'price_2': 700,
        'price_3': 1200,
      },
      {
        'title': 'TRASLADOS PLAYA DEL CARMEN - CANCUN (Marinas, cambios de hotel, parques etc...)',
        'price_1': 1400,
        'price_2': 1500,
        'price_3': 2000,
      },
      {
        'title': 'TRASLADOS ZH TULUM -CANCUN (Marinas, cambios de hotel, parques etc...)',
        'price_1': 2200,
        'price_2': 2300,
        'price_3': 3600,
      },
    ]
  }
}
